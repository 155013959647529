import type { ChainKey } from '@/config';
import type { PublicAppchain } from '@/shared/db/entities/appchains';
import { trpc } from '@/utils/trpc';

export function useAppchain(
  chainKey: ChainKey | undefined,
  paraId: number | undefined,
): {
  appchain: PublicAppchain | undefined;
  isLoading: boolean;
} {
  const { data, isLoading } = trpc.appchains.getAppchain.useQuery(
    {
      chainKey: chainKey!,
      paraId: paraId!,
    },
    {
      enabled: !!chainKey && !!paraId,
    },
  );

  return { appchain: data, isLoading };
}
