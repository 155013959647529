import { Alert } from '@/components/Alert';

export function CampaignAlert() {
  return (
    <Alert>
      {
        "You have successfully completed an Appchain deployment intent, your chain will not be deployed to be used while the Let's Forkin Dance incentive campaign is still active."
      }
    </Alert>
  );
}
