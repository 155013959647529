import { Alert } from '@/components/Alert';
import { useFreeCreditsAlert } from '@/components/AppchainCard/Alerts/state/Alerts.hooks';
import { useIsFlashbox } from '@/state/chain';

export function FreeCreditsAlert() {
  const isFlashbox = useIsFlashbox();
  const { isFreeCreditsAlertShown, setIsFreeCreditsAlertShown } =
    useFreeCreditsAlert();

  if (isFreeCreditsAlertShown || isFlashbox) return null;

  return (
    <Alert onClose={() => setIsFreeCreditsAlertShown(true)}>
      You have 60 days of free credits for block production. Your DANCE token
      balance shows the available amount and consumption rate per block. Ensure
      to top up your balance to keep block production running smoothly.
    </Alert>
  );
}
