import { CopyIcon } from '@/components/CopyIcon';
import { InnerCard } from '@/components/InnerCard';
import { OffsiteLink } from '@/components/OffsiteLink';
import type { Explorer } from '@/config';
import { Box, Group, Text, Title, useMantineTheme } from '@mantine/core';

interface Props {
  ws: string | undefined;
  http: string | undefined;
  explorers: Explorer[] | undefined;
}

export function Tooling({ ws, http, explorers }: Props) {
  const theme = useMantineTheme();

  return (
    <InnerCard style={{ flex: 1 }}>
      <Title order={4} size={14} c={'white'}>
        {'Tooling'}
      </Title>

      <Box mt={15}>
        {explorers && (
          <Group gap={0} mb={5} align={'center'}>
            <Text c={'gray.5'} size={'sm'} mr={5}>
              {'Explorer:'}
            </Text>
            <Group align={'center'} gap={0}>
              {explorers.map(
                ({ name, url }) =>
                  url && (
                    <OffsiteLink
                      key={name}
                      label={name}
                      url={url}
                      mr={5}
                      size={'sm'}
                      c={'white'}
                      iconColor={theme.other.colors.yellow}
                      iconSize={16}
                      td={'none'}
                    />
                  ),
              )}
            </Group>
          </Group>
        )}

        {http && (
          <Group gap={0} mb={5} align={'center'}>
            <Text c={'gray.5'} size={'sm'} mr={5}>
              {'RPC:'}
            </Text>
            <Group align={'center'} gap={5}>
              <Text size={'sm'}>{http} </Text>
              <CopyIcon
                value={http}
                size={18}
                color={theme.other.colors.yellow}
              />
            </Group>
          </Group>
        )}

        {ws && (
          <Group gap={0} mb={5} align={'center'}>
            <Text c={'gray.5'} size={'sm'} mr={5}>
              {'WS:'}
            </Text>
            <Group align={'center'} gap={5}>
              <Text size={'sm'}>{ws} </Text>
              <CopyIcon
                value={ws}
                size={18}
                color={theme.other.colors.yellow}
              />
            </Group>
          </Group>
        )}
      </Box>
    </InnerCard>
  );
}
